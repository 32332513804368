import { makeStyles } from '@material-ui/styles'
import { Space } from 'antd'

import ExportReportButton from './ExportReportButton'
import PrintReportButton from './PrintReportButton'

/**
 * @typedef ReportActionButtonsProps
 * @property {() => void} [onPrint]
 * @property {() => void} [onExport]
 */

/**
 *
 * @param {ReportActionButtonsProps} props
 * @returns
 */
export default function ReportActionButtons(props) {
  const { onPrint, onExport } = props
  const classes = useStyles(props)

  return (
    <Space direction='horizontal' className={classes.buttons}>
      {onPrint && <PrintReportButton onClick={onPrint} />}
      {onExport && <ExportReportButton onClick={onExport} />}
    </Space>
  )
}

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    gap: 16,
    margin: 8,
  },
}))
