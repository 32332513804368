import customerApi from '@/libs/api/customerApi'

import actions from '../actions'
import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-restricted-imports

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init() {
  return async (dispatch, getState) => {
    const logoutCallback = () => {
      dispatch(logout())
    }
    customerApi.setRefreshTokenCallback((auth) => dispatch(updateCustomerApiAuth(auth)))
    customerApi.setLogoutCallback(logoutCallback)

    // 嘗試從 localstorage 還原 customer
    await dispatch(restoreCustomer())

    dispatch({ type: ActionTypes.INIT })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function restoreCustomer() {
  return async (dispatch, getState) => {
    const customerAuth = JSON.parse(localStorage.getItem('customerAuth'))
    if (customerAuth) {
      // 設定給 customer api
      customerApi.setToken(customerAuth)
      // init fcm
      // dispatch(actions.app.initPushNotification());
      // 更新到 redux
      await dispatch(updateCustomerApiAuth(customerAuth))
      const customerInfo = await dispatch(getCustomerInfo())
      if (customerInfo?.data?.platforms.length !== 0) {
        await dispatch(actions.merchant.init(customerInfo))
        dispatch({ type: ActionTypes.LOGIN })
      } else {
        dispatch({ type: 'LOGOUT' })
      }
    }
  }
}

/**
 * @function
 * @param {CustomerApiAuthData} _auth
 * @returns {ThunkFunction}
 */
export function updateCustomerApiAuth(auth) {
  return async (dispatch, getState) => {
    localStorage.setItem('customerAuth', JSON.stringify(auth))

    // set token to customerApi
    customerApi.setToken(auth)
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getCustomerInfo() {
  return async (dispatch, getState) => {
    try {
      const customerInfo = await customerApi.getMe()
      dispatch({ type: ActionTypes.UPDATE_USER, payload: customerInfo })
      return customerInfo
    } catch (error) {
      console.log('getCustomerInfo error', error)
    }
  }
}

/**
 * @function
 * @param {File} file
 * @returns {ThunkFunction}
 */
export function uploadCustomerAvatar(file) {
  return async (dispatch, getState) => {
    try {
      const response = await customerApi.uploadCustomerAvatar(file)
      dispatch({ type: ActionTypes.UPDATE_USER, payload: response })
    } catch (error) {
      console.log('uploadCustomerAvatar error', error)
    }
  }
}

/**
 * @function
 * @param {CustomerApiCustomerInfo} updateFields
 * @returns {ThunkFunction}
 */
export function updateCustomerInfo(updateFields) {
  return async (dispatch, getState) => {
    try {
      await customerApi.updateCustomerInfo(updateFields)
      dispatch(getCustomerInfo())
    } catch (error) {
      console.log('updateCustomerInfo error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function login() {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.LOGIN })
    // document.location.href = '/';
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function logout() {
  return (dispatch, getState) => {
    dispatch({ type: 'LOGOUT' })
    localStorage.clear()
    document.location.href = '/'
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function isAdmin() {
  return (dispatch, getState) => {
    const user = getState().user.user
    const isAdmin = user?.data?.mobile && user.data.mobile.startsWith('+852999098')

    return isAdmin
  }
}
