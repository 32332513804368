import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import ExportReportButton from '@root/src/components/ReportActionButtons/ExportReportButton'
import {
  Typography as Text,
  Table,
  Modal,
  Row,
  Col,
  Button,
  Popover,
  Form,
  Select,
  Input,
  Space,
  Upload,
  message,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import ReportCard from '@/components/ReportCard'
import { useGroup, useGroupId } from '@/hooks/group'
import {
  useTotalMembersQuery,
  usePaginatedMembersQuery,
  useMemberPointRecordsQuery,
} from '@/hooks/queries/CRM/usePaginatedMembersQuery'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { dummyRequest } from '@/libs/uploadFile'

import BreadcrumbItem from '../BreadcrumbItem'
import Pagination from '../Pagination'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  searchBar: {
    marginBottom: 10,
  },
})

// 兌換記錄每頁顯示的數量
const pointsRecordPageSize = 5

export default function MemberList(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const [form] = useForm()
  const groupId = useGroupId()
  const group = useGroup()
  const [currentPage, setCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useState({ name: '', phone: '' })
  const [selectedMemberId, setSelectedMemberId] = useState(null)
  const { data: totalMembers } = useTotalMembersQuery(groupId)
  const { data: paginatedMembers, isLoading } = usePaginatedMembersQuery(
    groupId,
    currentPage,
    searchParams,
  )
  const { data: memberPointRecords } = useMemberPointRecordsQuery(groupId, selectedMemberId)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const user = useSelector((state) => state.user.user)
  const isAdmin = user?.data?.mobile && user.data.mobile.startsWith('+852999098')

  const selectedMember = useMemo(() => {
    return paginatedMembers?.data?.find((member) => member.id === selectedMemberId)
  }, [selectedMemberId])

  // 搜尋 member
  const onSearch = () => {
    const searchForm = form.getFieldValue()
    const params = {
      name: searchForm.name ?? '',
      phone: searchForm.phone ? searchForm.phonePrefix.concat(searchForm.phone) : '',
    }
    setSearchParams(params)
    setCurrentPage(0)
  }

  const memberListColumns = [
    {
      title: t('page.crm.memberList.table.name'),
      dataIndex: 'name',
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('page.crm.memberList.table.phone'),
      dataIndex: 'phone',
    },
    {
      title: t('page.crm.memberList.table.total'),
      dataIndex: 'total',
      // sorter: (a, b) => a.total - b.total,
      render: (total) => (
        <span>
          <Text>{currencyWithCommas(total)}</Text>
        </span>
      ),
    },
    {
      title: t('page.crm.memberList.table.bought'),
      dataIndex: 'bought',
      // sorter: (a, b) => a.bought - b.bought,
    },
    {
      title: t('page.crm.memberList.table.latestDate'),
      dataIndex: 'latestDate',
      // sorter: (a, b) => moment(a.latestDate) - moment(b.latestDate),
      render: (latestDate) =>
        latestDate ? (
          <Text>{moment(latestDate).format('YYYY-MM-DD')}</Text>
        ) : (
          <Text>{t('page.crm.memberList.table.no')}</Text>
        ),
    },
    {
      title: t('page.crm.memberList.table.joinedAt'),
      dataIndex: 'joinedAt',
      // sorter: (a, b) => moment(a.joinedAt) - moment(b.joinedAt),
      render: (joinedAt) => (
        <span>
          <Text>{moment(joinedAt).format('YYYY-MM-DD')}</Text>
        </span>
      ),
    },
  ]

  // 電話號碼加 prefix
  const prefixSelector = (
    <Form.Item name='phonePrefix' noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value='+852'>852</Select.Option>
      </Select>
    </Form.Item>
  )

  // search button popover
  const searchContent = (
    <Form
      form={form}
      onFinish={onSearch}
      autoComplete='off'
      initialValues={{ phonePrefix: '+852' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Item name='phone' noStyle>
          <Input
            className={classes.searchBar}
            addonBefore={prefixSelector}
            placeholder={t('page.crm.memberList.searchBy', {
              value: t('page.crm.memberList.table.phone'),
            })}
          />
        </Form.Item>
        <Form.Item name='name' noStyle>
          <Input
            className={classes.searchBar}
            placeholder={t('page.crm.memberList.searchBy', {
              value: t('page.crm.memberList.table.name'),
            })}
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              form.resetFields()
              setSearchParams({ name: '', phone: '' })
            }}
          >
            {t('page.crm.memberList.clearSearch')}
          </Button>
          <Button type='primary' shape='circle' icon={<SearchOutlined />} htmlType='submit' />
        </div>
      </div>
    </Form>
  )

  // member 詳細資料 Modal
  const MemberDetails = () => {
    // member 基本資料
    const basicInfo = [
      {
        value: 'name',
        md: 4,
      },
      {
        value: 'phone',
        md: 6,
      },
      {
        value: 'total',
        md: 5,
      },
      {
        value: 'bought',
        md: 4,
      },
      {
        value: 'points',
        md: 4,
      },
    ]

    // 訂單紀錄
    const orderRecordColumns = [
      {
        title: t('page.crm.memberList.modal.orderRecord.serial'),
        dataIndex: 'serial',
      },
      {
        title: t('page.crm.memberList.modal.orderRecord.roundedTotal'),
        dataIndex: 'roundedTotal',
      },
      {
        title: t('page.crm.memberList.modal.orderRecord.deliveryType'),
        dataIndex: 'deliveryType',
        render: (deliveryType) => {
          return t(`page.crm.memberList.modal.orderRecord.${deliveryType}`) ?? deliveryType
        },
      },
      {
        title: t('page.crm.memberList.modal.orderRecord.createdAt'),
        dataIndex: 'createdAt',
        render: (date) => {
          return moment(date).format('YYYY-MM-DD HH:mm')
        },
      },
    ]

    // 積分記錄
    const pointsRecordColumns = [
      {
        title: t('page.crm.memberList.modal.pointsRecord.serial'),
        dataIndex: 'orderSerial',
      },
      {
        title: t('page.crm.memberList.modal.pointsRecord.points'),
        dataIndex: 'points',
      },
      {
        title: t('page.crm.memberList.modal.pointsRecord.effectiveAt'),
        dataIndex: 'effectiveAt',
        render: (date) => {
          if (date) return moment(date).format('YYYY-MM-DD HH:mm')
          return '-'
        },
      },
      {
        title: t('page.crm.memberList.modal.pointsRecord.expiredAt'),
        dataIndex: 'expiredAt',
        render: (date) => {
          if (date) return moment(date).format('YYYY-MM-DD HH:mm')
          return '-'
        },
      },
    ]

    return (
      <Modal
        visible={selectedMemberId}
        width={1000}
        centered
        onCancel={() => {
          setSelectedMemberId(null)
        }}
        onOk={() => {
          setSelectedMemberId(null)
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Row justify='space-around'>
          {basicInfo.map((info) => {
            return (
              <Col xs={12} md={info.md} key={info.value}>
                {t(`page.crm.memberList.modal.${info.value}`)}
                <Text style={{ fontWeight: 'bold' }}>{selectedMember?.[info.value]}</Text>
              </Col>
            )
          })}
        </Row>
        <br />
        <Text className={classes.subtitle}>
          {t('page.crm.memberList.modal.pointsRecord.title')}
        </Text>
        <br />
        <Table
          columns={pointsRecordColumns}
          dataSource={memberPointRecords}
          scroll={{ x: 800 }}
          pagination={false}
        />
        <br />
        <Text className={classes.subtitle}>{t('page.crm.memberList.modal.orderRecord.title')}</Text>
        <br />
        <Table
          columns={orderRecordColumns}
          dataSource={selectedMember?.orders.slice(0, 5)}
          rowKey={(record) => record.id}
          scroll={{ x: 800 }}
          pagination={false}
        />
        <br />
        <Text style={{ textAlign: 'center' }}>
          {t('page.crm.memberList.modal.recordLimitText', { limit: pointsRecordPageSize })}
        </Text>
      </Modal>
    )
  }

  const onExport = async () => {
    try {
      await dimorderApi.group(groupId)?.exportCrmUserCsv()
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }
  const limitFileType = (file) => {
    const isValidType = true
    if (!isValidType) {
      message.error(t('app.component.userInfoModal.error.file.type'))
    }
    const isLtUploadSize = file.size / 1024 / 1024 < 2
    if (!isLtUploadSize) {
      message.error(t('app.component.userInfoModal.error.file.size'))
    }
    return isValidType && isLtUploadSize
  }
  const handleUploadPoints = async (info) => {
    // if (info.file.status === 'uploading') {
    // }
    if (info.file.status === 'done') {
      try {
        await dimorderApi.instance(activeMerchant.id)?.uploadPoints(info.file.originFileObj)
        alert(t('app.common.importSucess'))
      } catch (error) {
        alert(t('app.common.importError'))
      }
    }
  }

  // if (members?.data?.length === 0) return <Loading />

  return (
    <PageContainer
      ghost
      breadcrumb
      header={{
        breadcrumb: {
          routes: [
            {
              path: '/crm',
              breadcrumbName: t('page.crm.title'),
            },
            {
              path: '/crm/:groupId',
              breadcrumbName: group?.name,
            },
            {
              path: '/crm/:id/memberlist',
              breadcrumbName: t('page.crm.memberList.title'),
            },
          ],
          itemRender: (route, params, routes, paths) => {
            return <BreadcrumbItem route={route} params={params} routes={routes} paths={paths} />
          },
        },
        title: '',
        style: {
          paddingTop: '24px',
        },
      }}
    >
      <ReportCard>
        <Row
          style={{ display: 'flex', alignSelf: 'center', justifyContent: 'space-between', gap: 10 }}
        >
          <Text style={{ alignSelf: 'center' }}>
            {t('page.crm.memberList.table.totalMember')}：{totalMembers}
          </Text>
          <Space direction='horizontal'>
            {isAdmin && (
              <Upload
                name='file'
                onChange={handleUploadPoints}
                beforeUpload={limitFileType}
                showUploadList={false}
                customRequest={dummyRequest}
              >
                <Button type='primary' icon={<UploadOutlined />}>
                  上傳
                </Button>
              </Upload>
            )}
            <ExportReportButton onClick={onExport} />
            <Popover placement='bottomLeft' content={searchContent} trigger='click'>
              <Button type='primary' icon={<SearchOutlined />} style={{ borderRadius: 5 }}>
                {t('page.crm.memberList.search')}
              </Button>
            </Popover>
          </Space>
        </Row>
        <br />
        <Table
          columns={memberListColumns}
          dataSource={paginatedMembers?.data}
          scroll={{ x: 800 }}
          rowKey={(record) => record.id}
          pagination={false}
          loading={isLoading}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                setSelectedMemberId(record.id)
              },
            }
          }}
        />
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          hasNextPage={!!paginatedMembers?.meta?.next}
          loading={isLoading}
        />
      </ReportCard>
      <MemberDetails />
    </PageContainer>
  )
}
