import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

import config from '@/config'

import customerApi from './customerApi'

export class DimorderApi {
  constructor(merchantId, username, token = '', refreshToken = '', refreshFailedCallback) {
    this.id = merchantId
    this.username = username
    this.refreshToken = refreshToken
    this.refreshFailedCallback = refreshFailedCallback
    this.axios = axios.create({
      baseURL: config.api.dimorder,
    })
    this.axiosNode = axios.create({
      baseURL: config.api.node,
    })
    this.axiosLocal = axios.create({
      baseURL: config.api.portalLocal, // localhost fake api
    })
    this.setToken(token)
    this.axiosNode.interceptors.response.use(
      (data) => data,
      async (error) => {
        const res = error.response
        const errorMessage = res?.data?.message ?? ''
        if (res?.status === 500 && errorMessage === 'UnauthorizedError: jwt expired') {
          try {
            await this.refresh()
          } catch (refreshError) {
            // refresh 失敗時，應將 merchant 登出，UI 處裡重新登入後再用 updateMerchant 更新 platforms token

            this.refreshFailedCallback?.(
              refreshError,
              this.merchantName,
              this.merchantShortCode,
              this.username,
            )
            // 丟出原本的 error
            return await Promise.reject(error)
          }

          // retry
          // 更新上次 request config 中的 token 以便用同一個 config 重試
          error.config.headers.Authorization = this.axiosNode.defaults.headers.common.Authorization
          return await this.axiosNode.request(error.config)
        }
        return await Promise.reject(error)
      },
    )
  }

  static async scopelogin(param) {
    const url = `${config.api.dimorder}/m/user/scopelogin/${param.merchantAccount}`
    const params = {
      merchantUsername: param.merchantAccount,
      username: param.username,
      password: param.password,
    }

    const response = await axios.post(url, params, {
      skipAuthorization: true,
    })

    return response.data
  }

  async refresh() {
    if (!this.refreshToken) {
      throw new Error('No Refresh Token')
    }
    const endpoint = '/m/user/refresh'
    const response = await this.axios.post(endpoint, {
      token: this.refreshToken,
    })

    const { token, refresh } = response.data

    // update 本 instance 的 token
    this.setToken(token)
    this.setRefreshToken(refresh)

    // update customer account 的 platforms token
    customerApi.updateMerchant(token, refresh)
  }

  setRefreshToken(refreshToken) {
    this.refreshToken = refreshToken
  }

  setToken(token) {
    this.token = token
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`
    this.axiosNode.defaults.headers.common.Authorization = `Bearer ${token}`
    this.axiosLocal.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  setLanguage(locale) {
    this.locale = locale
    this.axiosNode.defaults.headers.common['Accept-Language'] = locale
    this.axios.defaults.headers.common['Accept-Language'] = locale
  }

  async getMerchant() {
    const response = await this.axios.get('/m/merchant/')
    this.merchantName = response.data.name
    this.merchantShortCode = response.data.shortCode

    return response.data
  }

  async getDailyClosing(param) {
    const params = {
      from: param.startX,
      to: param.endX,
    }
    const response = await this.axios.get('/m/order/stat/v2/summary', { params })
    return response.data
  }

  /**
   * 座位群組的單量與銷售總額
   * @returns
   */
  async getTableGroupSalesSection(param) {
    const params = {
      from: param.startX,
      to: param.endX,
    }
    const response = await this.axios.get('/m/order/stat/table', { params })
    // response.data = {"data":[{"tableName":"BAR","count":4,"totalSales":451}]}
    const section = {
      type: 'tableGroupSales',
      data: response.data?.data ?? [],
    }
    return section
  }

  /**
   * 商品選項
   * @returns
   */
  async getOptionSalesSection(param) {
    const params = {
      from: param.startX,
      to: param.endX,
    }
    const response = await this.axios.get('/m/order/stat/option', { params })
    // response.data = '{"data":[{"title":"麻辣火鍋 - 加菜","count":20,"totalSales":50},{"title":"麻辣火鍋 - 加肉","count":20,"totalSales":30},{"title":"原味火鍋 - 加菜","count":20,"totalSales":100},{"title":"原味火鍋 - 加肉","count":20,"totalSales":50}]}'
    const sortedData = _.orderBy(
      response.data?.data ?? [],
      ['totalSales', 'title'],
      ['desc', 'asc'],
    )
    const section = {
      type: 'productOptionSales',
      data: sortedData,
    }
    return section
  }

  async getDashboard(date) {
    const endpoint = '/m/merchantportal/dashboard'
    const params = {
      date,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getSales(date, limit) {
    const endpoint = `/m/merchantportal/sales`
    const params = {
      date,
      limit,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getOverview(param) {
    const endpoint = '/m/merchantportal/overview'
    const params = {
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
      delivery_type: param.delivery_type,
      min_people: param.min_people,
      max_people: param.max_people,
      group_by: param.group_by,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getTransaction(param) {
    const endpoint = '/m/merchantportal/orders'
    const params = {
      date_start: param.start,
      date_end: param.end,
      delivery_type: param.delivery_type,
      order_number: param.order_number,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getHourlySalesReport(param) {
    const endpoint = '/m/merchantportal/salesreportbyhour'
    const params = {
      date_start: param.start,
      date_end: param.end,
      compare_date_start: param.compare_date_start,
      compare_date_end: param.compare_date_end,
      delivery_type: param.delivery_type,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getSalesReportByItem(param) {
    const endpoint = '/m/merchantportal/salesreportbyitem'
    const params = {
      date_start: param.start,
      date_end: param.end,
      compare_date_start: param.compare_date_start,
      compare_date_end: param.compare_date_end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
      delivery_type: param.delivery_type,
      min_people: param.min_people,
      max_people: param.max_people,
      category_ids: param.category_ids,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getSalesReportByCategory(param) {
    const endpoint = '/m/merchantportal/salesreportbycategory'
    const params = {
      date_start: param.start,
      date_end: param.end,
    }

    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getVoidReport(param, type) {
    const endpoint = '/m/merchantportal/cancelledorders'
    const params = {
      type,
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getDiscountReport(param) {
    const endpoint = '/m/merchantportal/discountreport'
    const params = {
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
      delivery_type: param.delivery_type,
      min_people: param.min_people,
      max_people: param.max_people,
    }

    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getPaymentMethodReport(param) {
    const endpoint = '/m/merchantportal/paymentmethodreport'
    const params = {
      date_start: param.start,
      date_end: param.end,
      payment_methods: param.payment_methods,
    }

    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getKitchenDepartmentReports(param) {
    const params = {
      from: param.startX,
      to: param.endX,
    }
    const endpoint = '/m/order/stat/kitchendepartmentsummary'

    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getCustomerReport(param) {
    const endpoint = '/m/merchantportal/customerreport'

    const params = {
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getPreparingTimeReport(param) {
    const endpoint = '/m/merchantportal/preparationreport'

    const params = {
      date_start: param.start,
      date_end: param.end,
    }

    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getPayInAndOutReport(param) {
    const endpoint = 'm/cash'

    const params = {
      from: param.startX,
      to: param.endX,
    }

    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async downloadFile(url, params) {
    const { data, headers } = await this.axios.get(url, {
      responseType: 'blob',
      params,
    })
    const headersPrefix = 'attachment; filename='
    const filename = decodeURIComponent(headers['content-disposition'].replace(headersPrefix, ''))
    const objectURL = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = objectURL
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  async exportTransactionCsv(param) {
    const endpoint = '/m/merchantportal/transactionscsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      delivery_type: param.delivery_type,
      order_number: param.order_number,
    }
    await this.downloadFile(endpoint, params)
  }

  async exportOverviewCsv(param) {
    const endpoint = '/m/merchantportal/overviewcsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
      delivery_type: param.delivery_type,
      min_people: param.min_people,
      max_people: param.max_people,
      group_by: param.group_by,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportSalesReportByHourCsv(param) {
    const endpoint = '/m/merchantportal/salesreportbyhourcsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      compare_date_start: param.compare_date_start,
      compare_date_end: param.compare_date_end,
      delivery_type: param.delivery_type,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportVoidReportCsv(param, type) {
    const endpoint = '/m/merchantportal/cancelledorderscsv'

    const params = {
      type,
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportPaymentMethodReportCsv(param) {
    const endpoint = '/m/merchantportal/paymentmethodreportcsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      payment_methods: param.payment_methods,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportKitchentDepartmentReportCsv(param) {
    const endpoint = '/m/order/stat/kitchendepartmentsummary'

    const params = {
      from: param.startX,
      to: param.endX,
      export_type: 'csv',
    }

    await this.downloadFile(endpoint, params)
  }

  async exportSalesReportByCategoryCsv(param) {
    const endpoint = '/m/merchantportal/salesreportbycategorycsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportDiscountReportCsv(param) {
    const endpoint = '/m/merchantportal/discountreportcsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
      delivery_type: param.delivery_type,
      min_people: param.min_people,
      max_people: param.max_people,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportCustomerReportCsv(param) {
    const endpoint = '/m/merchantportal/customerreportcsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportSalesReportByItemCsv(param) {
    const endpoint = '/m/merchantportal/salesreportbyitemcsv'

    const params = {
      date_start: param.start,
      date_end: param.end,
      compare_date_start: param.compare_date_start,
      compare_date_end: param.compare_date_end,
      interval_start: param.interval_start,
      interval_end: param.interval_end,
      delivery_type: param.delivery_type,
      min_people: param.min_people,
      max_people: param.max_people,
      category_ids: param.category_ids,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportDailyClosingCsv(param) {
    const endpoint = '/m/merchantportal/dailyclosingcsv'

    const params = {
      from: param.startX,
      to: param.endX,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportPayInAndOutReportCsv(param) {
    const endpoint = 'm/cash'

    const params = {
      from: param.startX,
      to: param.endX,
      csv: true,
    }

    await this.downloadFile(endpoint, params)
  }

  async exportCrmUserCsv() {
    const endpoint = '/m/group/user'

    const params = {
      limit: Number.MAX_SAFE_INTEGER,
      csv: true,
    }
    await this.downloadFile(endpoint, params)
  }

  async exportOptionSalesReport(param) {
    const endpoint = '/m/merchantportal/optionreportcsv'

    const params = {
      from: param.startX,
      to: param.endX,
    }

    await this.downloadFile(endpoint, params)
  }

  async dimSupplyOrdering(param) {
    const endpoint = '/m/supply'

    const params = {
      items: param.items,
      paymentMethod: param.paymentMethod,
      pickupAt: param.pickupAt,
      phone: param.phone,
      name: param.name,
      deliveryAddress: param.deliveryAddress,
    }

    const response = await this.axios.post(endpoint, params)

    return response.data
  }

  async dimorderStatement(param) {
    const endpoint = `/m/report/job`
    const params = {
      deliveryType: param.deliveryType,
      startedAt: param.start,
      endedAt: moment(param.end).endOf('minute').toISOString(),
    }
    const response = await this.axios.get(endpoint, { params })

    return response.data
  }

  async getPaymentMethods() {
    const endpoint = '/m/merchantportal/paymentmethods'

    const response = await this.axios.get(endpoint)

    return response.data
  }

  async getCategories() {
    const endpoint = '/m/merchantportal/categories'

    const response = await this.axios.get(endpoint)

    return response.data
  }

  /**
   * @param {File} file
   * @returns {Promise}
   */
  async uploadMenu(file) {
    const formData = new FormData()
    formData.append('file', file)

    /** @type {AxiosResponse} */
    const response = await this.axios.post('/m/menuimport/excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  }

  /**
   * @param {File} file
   * @returns {Promise}
   */
  async uploadPoints(file) {
    const formData = new FormData()
    formData.append('file', file)

    /** @type {AxiosResponse} */
    const response = await this.axios.post('/m/group/pointimport', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  }

  async downloadMenu() {
    const endpoint = '/m/menuexport/excel'
    window.open(config.api.dimorder + endpoint + `?token=${this.token}`)
  }

  /**
   * @typedef AddOctopusAccountParams
   * @property {String} password
   * @property {String} pfx
   * @property {String} account
   * @param {AddOctopusAccountParams} params
   * @returns
   */
  async addOctopusAccount(params) {
    const endpoint = '/m/merchantportal/octopus'
    const response = await this.axios.post(config.api.dimorder + endpoint, params)
    return response
  }

  /**
   * @returns
   */
  async getOctopusAccounts() {
    const endpoint = '/m/merchantportal/octopus'
    const response = await this.axios.get(config.api.dimorder + endpoint)
    return response
  }

  /**
   * @param {String} account
   */
  async deleteOctopusAccount(account) {
    const endpoint = '/m/merchantportal/octopus/'
    await this.axios.delete(config.api.dimorder + endpoint + account)
  }

  /**
   * @param {Array} tableGeometry
   */
  async updateTableGeometry(tableGeometry) {
    const endpoint = `/m/merchant/setting/tablegeometry`
    const params = { tableGeometry }
    const response = await this.axios.post(endpoint, params)
    return response.data
  }

  /**
   * @param {boolean} enabled
   */
  async enableFloorPlan(enabled) {
    const endpoint = `/m/merchant/setting/enablefloorplan`
    const params = { value: enabled }
    const response = await this.axios.post(endpoint, params)
    return response.data
  }

  async getPrinterSetting() {
    const endpoint = `/m/merchant/printersetting`
    const response = await this.axiosNode.get(endpoint)
    return response.data
  }

  /**
   * 取得 MR user scope (權限) 資料
   */
  async getUserInfo() {
    const endpoint = '/m/user/info'
    const response = await this.axios.get(endpoint)
    return response.data
  }

  // * Group Api

  async getCRMGroup() {
    const endpoint = `/m/group`
    const response = await this.axios.get(endpoint)
    return response.data
  }

  /**
   * @param {Object} group
   */
  async updateCRMGroup(group) {
    const endpoint = `/m/group`
    const response = await this.axios.put(endpoint, group)
    return response.data
  }

  async getUserCount() {
    const endpoint = `/m/group/usercount`
    const response = await this.axios.get(endpoint)
    return response.data
  }

  async getGroupUser(params) {
    const endpoint = `/m/group/user`
    const response = await this.axios.get(endpoint, { params })
    return response.data
  }

  async getUserCRMPointsRecord(id, params) {
    const endpoint = `/m/group/user/${id}/point`
    const response = await this.axios.get(endpoint, { params })
    return response.data
  }

  /**
   * @param {Object} params
   */
  async getRewards(params) {
    const endpoint = `/m/reward`
    const response = await this.axios.get(endpoint, { params })
    return response.data
  }

  /**
   * @param {string} id
   * @param {File} file
   */
  async updateRewardImage(id, file) {
    const endpoint = `/m/reward/${id}/image`
    const data = new FormData()
    data.append('file', file)
    const response = await this.axios.post(endpoint, data)
    return response.data
  }

  /**
   * @param {Object} reward
   */
  async addReward(reward) {
    const endpoint = `/m/reward`
    const response = await this.axios.post(endpoint, reward)
    return response.data
  }

  /**
   * @param {Object} reward
   * @param {String} id
   */
  async updateReward(id, reward) {
    const endpoint = `/m/reward/${id}`
    const response = await this.axios.put(endpoint, reward)
    return response.data
  }

  /**
   * @param {String} id
   */
  async deleteReward(id) {
    const endpoint = `/m/reward/${id}`
    await this.axios.delete(endpoint)
  }

  /**
   * @param {Object} params
   */
  async getPointRules(params) {
    const endpoint = `/m/pointrule`
    const response = await this.axios.get(endpoint, { params })
    return response.data
  }

  /**
   * @param {Object} pointRule
   */
  async addPointRule(pointRule) {
    const endpoint = `/m/pointrule`
    const response = await this.axios.post(endpoint, pointRule)
    return response.data
  }

  /**
   * @param {Object} pointRule
   * @param {String} id
   */
  async updatePointRule(id, pointRule) {
    const endpoint = `/m/pointrule/${id}`
    const response = await this.axios.put(endpoint, pointRule)
    return response.data
  }

  /**
   * @param {String} id
   */
  async deletePointRule(id) {
    const endpoint = `/m/pointrule/${id}`
    await this.axios.delete(endpoint)
  }

  /**
   * @param {File} file
   */
  async updateGroupImage(file) {
    const endpoint = `/m/group/image`
    const data = new FormData()
    data.append('file', file)
    const response = await this.axios.post(endpoint, data)
    return response.data
  }
}

/**
 * @typedef DimorderApiManager {Object}
 * @property {string} lastMerchantId
 * @property {{[merchantId: string]: DimorderApi}} instances
 * @property {{[groupId: string]: DimorderApi}} groups
 * @property {function(string): DimorderApi | undefined} instance
 * @property {function(string): DimorderApi | undefined} create
 */
const dimorderApiManager = {
  lastMerchantId: null,
  lastGroupId: null,
  instances: {},
  groups: {},
  /**
   * 拿到指定的 merchant 的 DimporderApi instance
   * @param {string} [merchantId] 不給 merchantId 的話自動用上次使用的 (建議還是要給)
   * @returns {DimorderApi | undefined}
   */
  instance(merchantId = this.lastMerchantId) {
    this.lastMerchantId = merchantId
    return this.instances[merchantId]
  },
  /**
   * 拿到指定的 gruop 的 DimporderApi instance
   * @param {string} [groupId] 不給 groupId 的話自動用上次使用的 (建議還是要給)
   * @returns {DimorderApi | undefined}
   */
  group(groupId = this.lastGroupId) {
    this.lastGroupId = groupId
    return this.groups[groupId]
  },
  /**
   * 建立 merchant 的 DimporderApi instance
   * @param {string} merchantId
   * @param {string} username
   * @param {string} accessToken
   * @param {string} refreshToken
   * @returns {DimorderApi}
   */
  create(merchantId, username, accessToken, refreshToken, refreshFailedCallback) {
    // 建立 instance
    const dimorderApi = new DimorderApi(
      merchantId,
      username,
      accessToken,
      refreshToken,
      refreshFailedCallback,
    )

    // 存入 manager 中
    this.instances[merchantId] = dimorderApi

    // 紀錄最後使用的 id
    this.lastMerchantId = merchantId
    return dimorderApi
  },
  /**
   * 將 group id 和 merchant 的 DimorderApi 關聯
   * @param {string} groupId
   * @param {string} merchantId
   * @returns {DimorderApi}
   */
  addGroup(groupId, merchantId) {
    this.lastGroupId = groupId
    const dimorderApi = this.instance(merchantId)
    this.groups[groupId] = dimorderApi
    return dimorderApi
  },
}

export default dimorderApiManager
